<template>
  <div style="width:80%; display:flex; flex-direction: column">
    <h1>Edition utilisateur</h1>
    <secured-area :operations="['View_User', 'Create_User', 'Edit_User', 'Add_User_To_Role', 'Remove_User_From_Role', 'View_Users_In_Roles', 'View_Users_Rights']">
        <collapse-panel title="Généralités">
          <detail
            v-model="editedUser"
            @submit="saveUser"
            @reset="loadAndRefreshUser"
          />
        </collapse-panel>
    </secured-area>
    <secured-area :operations="['View_Users_In_Roles', 'Add_User_To_Role', 'Remove_User_From_Role']"  :hideWhenAccessIsRejected="true">
        <collapse-panel title="Roles">
          <roles
            v-model="roles"
            @submit="saveUserRoles"
            @reset="loadAndRefreshUserRoles"
          />
        </collapse-panel>
    </secured-area>
    <secured-area :operations="['View_Users_Rights']" :hideWhenAccessIsRejected="true">
        <collapse-panel title="Droits découlant des rôles sélectionnés">
          A faire
        </collapse-panel>
    </secured-area>
  </div>
</template>

<script>
  import SecuredArea from '@/components/Security/SecuredArea.vue';
  import Detail from "@/components/Security/Users/Detail.vue";
  import Roles from "@/components/Security/Users/Roles.vue";
  import axios from 'axios';
  import CollapsePanel from '@/components/Panels/CollapsePanel.vue';

  export default {
    components: { SecuredArea, Detail, Roles, CollapsePanel},
    data(){
        return{
            id: null,
            editedUser: null,
            roles: [],
            operations: [],
        }
    },
    methods:{
      loadAndRefreshUser(){
        this.loadAndRefresh(true, false, false);
      },
      loadAndRefreshUserRoles(){
        this.loadAndRefresh(false, true, false);
      },
      loadAndRefreshUserOperations(){
        this.loadAndRefresh(false, false, true);
      },
      loadAndRefresh(withUser=true, withRoles=true, withOperations=true){
        if(this.id != null)
        {
          var url = this.getUrl('/api/Security/getUser.php');

          var criteria = { user_id: this.id, includeUser: withUser, includeRoles: withRoles, includeOperations: withOperations };
          console.log(url, criteria);
          axios.post(url, criteria)
            .then((response) => {
              console.log('getUser => ', response.data);
              if(withUser) { this.editedUser = response.data.user; }
              if(withRoles) { this.roles = response.data.roles.map(r => r.name); }
              if(withOperations) { this.operations = response.data.operations; }
            })
            .catch(error => {
              console.log(error);
            })
        }
      },
      saveUser(buffer){
        console.log('saveUser', buffer);
       },
      saveUserRoles(buffer){
        console.log('saveUserRoles', buffer);
      },
    },
    mounted(){
      this.id = this.$route.params["id"];
      this.loadAndRefresh();
      this.$hideLoader();
    },
    watch: {
        $route(to, from) {
            if (to !== from) {
                console.log("/Security/Users/Edit.vue > route id changed : ", to);
                this.id = to;
                this.loadAndRefresh();
            }
        },
    },
  }
</script>
